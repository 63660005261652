import Doc from "src/images/nav/apps/nav_doc_icon.inline.svg"
import Fax from "src/images/nav/apps/nav_fax_icon.inline.svg"
// import Ship from "src/images/nav/apps/nav_ship_icon.inline.svg"
import Sig from "src/images/nav/apps/nav_sig_icon.inline.svg"
// import Payments from "src/images/nav/apps/nav_payments_icon.inline.svg"
// import Forms from "src/images/nav/apps/nav_forms_icon.inline.svg"

const appNavigation = [
  {
    id: "documents",
    text: "documents",
    to: "/documents",
    icon: Doc,
  },
  {
    id: "fax",
    text: "fax",
    to: "/fax",
    icon: Fax,
  },
  {
    id: "signature",
    text: "signature",
    to: "/signature",
    icon: Sig,
  },
  // {
  //   id: "ship",
  //   text: "ship",
  //   to: "/ship",
  //   icon: Ship,
  // },
  // {
  //   id: "payments",
  //   text: "payments",
  //   to: "/payments",
  //   icon: Payments,
  // },
  // {
  //   id: "forms",
  //   text: "forms",
  //   to: "/forms",
  //   icon: Forms,
  // },
]

export default appNavigation
