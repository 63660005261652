import { useMemo } from "react"
import keyBy from "lodash/keyBy"
import groupBy from "lodash/groupBy"

/**
 * @description Contentful components Hook
 *
 * @param {Array<{componentId: String}>} components - contentful componentss with componentsId
 * @returns [] - array keyed by componentsId
 */

const useContentfulComponent = (components) => {
  return useMemo(() => {
    // const { ContentfulComponent, ContentfulMedia, ContentfulTestimonial } =
    const { ContentfulComponent, ContentfulMedia } = groupBy(
      components,
      ({ internal: { type } }) => type
    )

    return {
      // testimonials: ContentfulTestimonial,
      ...keyBy(ContentfulMedia, "mediaId"),
      ...keyBy(ContentfulComponent, "componentId"),
    }
  }, [components])
}

export default useContentfulComponent
