const appTextFromSlug = {
  documents: "documents",
  fax: "fax",
  signature: "signature",
  ship: "ship",
  payments: "payments",
  sms: "sms",
  forms: "forms",
  "email-open-tracker": "Email Open tracker",
  "meeting-request-notifier": "Meeting Request Notifier",
}

export default appTextFromSlug
