const appColors = {
  documents: {
    light: "var(--blue)",
    dark: "var(--blueDark)",
    darker: "var(--blueDarker)",
    boxShadow: "0px 35px 40px -30px #214445",
    gradient: "linear-gradient(180deg, #297E81 0%, #2C2C2F 100%)",
    textGradient: "linear-gradient(90.36deg, #30BBC2 -16.1%, #78DB93 130.3%)",
  },
  fax: {
    light: "var(--green)",
    dark: "var(--greenDark)",
    darker: "var(--greenDarker)",
    boxShadow: "0px 35px 40px -30px #265837",
    gradient: "linear-gradient(180deg, #4B9665 0%, #2C2C2F 100%)",
    textGradient: "linear-gradient(90deg, #78DB93 0%, #AED581 100%)",
  },
  signature: {
    light: "var(--red)",
    dark: "var(--redDark)",
    darker: "var(--redDarker)",
    boxShadow: "0px 35px 40px -30px #5A3838",
    gradient: "linear-gradient(180deg, #A15D5D 0%, #2C2C2F 100%)",
    textGradient: "linear-gradient(90deg, #E1A67D 0%, #EF8584 100%)",
  },
  ship: {
    light: "var(--purple)",
    dark: "var(--purpleDark)",
    darker: "var(--purpleDarker)",
    boxShadow: "0px 35px 40px -30px #404159",
    gradient: "linear-gradient(180deg, #70719A 0%, #2C2C2F 100%)",
    textGradient: "linear-gradient(90deg, #C696B6 0%, #A2A5E1 100%)",
  },
  yellow: {
    light: "var(--yellow)",
  },
  "email-open-tracker": {
    light: "var(--darkBG)",
    dark: "var(--darkBG)",
    darker: "var(--darkBG)",
    boxShadow: "var(--card-shadow)",
    gradient: "var(opero-gradient)",
    textGradient: "var(opero-gradient)",
  },
  "meeting-request-notifier": {
    light: "var(--darkBG)",
    dark: "var(--darkBG)",
    darker: "var(--darkBG)",
    boxShadow: "var(--card-shadow)",
    gradient: "var(opero-gradient)",
    textGradient: "var(opero-gradient)",
  },
  payments: {
    light: "var(--teal)",
    dark: "var(--tealDark)",
    darker: "var(--tealDarker)",
    boxShadow: "0px 35px 40px -30px #0C463A",
    gradient: "linear-gradient(180deg, #228F78 0%, #2C2C2F 100%)",
    textGradient: "linear-gradient(90deg, #45C4B4 0%, #69D59C 100%)",
  },
  forms: {
    light: "var(--yellow)",
    dark: "var(--yellowDark)",
    darker: "var(--yellowDarker)",
    boxShadow: " 0px 35px 40px -30px #53500D",
    gradient: "linear-gradient(180deg, #A79144 0%, #2C2C2F 100%)",
    textGradient: "linear-gradient(90deg, #BEC160 0%, #C9AD5C 100%)",
  },
  sms: {
    light: "#66676D",
    dark: "#66676D",
    darker: "#66676D",
    boxShadow: "0px 35px 40px -30px #66676D",
    gradient: "linear-gradient(180deg, #66676D 0%, #2C2C2F 100%)",
    textGradient: "linear-gradient(90deg, #66676D 0%, #A2A5E1 100%)",
  },
  disabled: {
    light: "#66676D",
    dark: "#66676D",
    darker: "#66676D",
    boxShadow: "0px 35px 40px -30px #66676D",
    gradient: "linear-gradient(180deg, #66676D 0%, #2C2C2F 100%)",
    textGradient: "linear-gradient(90deg, #66676D 0%, #A2A5E1 100%)",
  },
}

export default appColors
