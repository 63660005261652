import React from "react"
import styled from "styled-components"
import classNames from "classnames"
import { ValueProp, BasicCard, Link } from "src/components/common"

import { device, gradientBorder, appColors } from "utils"

const AppCard = ({ vp, app }) => {
  const iconOffset = vp.icon ? vp.icon.file.details.image.height / 2 : 0

  const to = vp.to === "/sms" ? null : vp.to

  return (
    <StyledAppCard
      iconOffset={iconOffset}
      className={classNames(app, vp.vpId)}
      app={app}
    >
      <Link to={to} href={vp.href} notLink={!to && !vp.href} className="link">
        <BasicCard>
          <ValueProp
            {...vp}
            as="div"
            ctasTheme={[{ isLink: true }]}
            disableLink
            subtitleAs="pSmall"
            inlineSvg
          />
          {/* {app === "disabled" && <div style={{ height: 45 }} />} */}
        </BasicCard>
      </Link>
    </StyledAppCard>
  )
}

const StyledAppCard = styled.li`
  position: relative;
  --tg-grid-gap: var(--sp-8);

  /* hover */

  transition: 0.1s transform ease-in-out;
  will-change: transform;

  &.disabled {
    pointer-events: none;
    filter: grayscale(1);
  }

  &:hover {
    transform: scale(1.01);

    &.documents,
    &.fax,
    &.signature,
    &.ship {
      svg > rect:nth-child(3) {
        stroke: ${({ app }) => appColors[app]?.dark};
      }
    }

    &.payments,
    &.forms {
      svg > rect:nth-child(2) {
        stroke: ${({ app }) => appColors[app]?.dark};
      }
    }

    .basicCard {
      box-shadow: ${({ app }) => appColors[app]?.boxShadow};
      ${({ app }) =>
        gradientBorder({
          background: "var(--card-shade_1)",
          gradient: appColors[app]?.gradient,
        })};
    }
  }

  .link {
    display: block;
  }

  .basicCard {
    padding-top: var(--sp-64);
    position: relative;

    @media ${device.laptop} {
      padding-top: var(--sp-56);
    }

    .vp_textContainer {
      z-index: 2;
      position: relative;

      .title {
        font-size: 2.125rem;
        strong {
          color: ${({ app }) => appColors[app]?.light};
        }
      }
    }

    .vp_mediaContainer {
      z-index: 2;
      position: absolute;
      left: var(--card-padding);
      top: calc(-95px / 2);
      width: 95px;
      height: 95px;

      border-radius: 50%;
      box-shadow: var(--card-shadow);

      @media ${device.laptop} {
        top: ${({ iconOffset }) => `-${iconOffset}px`};
        width: initial;
        height: initial;
        left: 50%;
        transform: translateX(-50%);
      }

      img {
        width: 100%;
        height: 100%;
      }
    }

    .vp_ctaContainer {
      .notLink {
        color: ${({ app }) => appColors[app]?.light};
      }
    }
  }
`

export default AppCard
