import React from "react"
import styled from "styled-components"

const Test = () => {
  return <StyledTest>test</StyledTest>
}

const StyledTest = styled.div``

export default Test
