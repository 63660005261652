import React from "react"
import styled from "styled-components"
import classNames from "classnames"
import { TextGroup, Media, Cta } from "components/common"

import { gradientBorder, device } from "utils"

const FeatureCard = ({ icon, title, subtitle, media, ctas, vpId }) => {
  const hatIcon = icon ? { desktop: icon } : ""

  return (
    <StyledFeatureCard className={classNames(vpId, "featureCard")}>
      <div className="text_container">
        <TextGroup
          hatIcon={hatIcon}
          title={title}
          titleAs="h2"
          subtitle={subtitle}
          cta={ctas ? () => <Cta ctas={ctas} /> : null}
        />
      </div>
      {media && (
        <div className="media_container">
          <Media media={media} />
        </div>
      )}
    </StyledFeatureCard>
  )
}

const StyledFeatureCard = styled.li`
  display: grid;
  border-radius: var(--card-radius);
  box-shadow: var(--card-shadow);

  grid-template-columns: 1fr;
  @media ${device.laptop} {
    grid-template-columns: 1fr 1fr;
  }

  ${gradientBorder({
    background: "var(--card-shade_1)",
    gradient: "var(--card-border)",
  })};

  .text_container {
    padding: var(--sp-32);
    position: relative;

    @media ${device.laptop} {
      padding: var(--sp-80);
    }
  }

  .media_container {
    background: var(--card-shade_2);
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: var(--card-radius);
    border-bottom-right-radius: var(--card-radius);
    position: relative;

    @media ${device.laptop} {
      padding: 0;
      border-bottom-left-radius: 0;
      border-top-right-radius: var(--card-radius);
      border-bottom-right-radius: var(--card-radius);
    }

    &::after {
      content: "";
      left: 0;
      top: 0;
      background: var(--card-border);
      position: absolute;
      width: 100%;
      height: 2px;
      @media ${device.laptop} {
        height: 100%;
        width: 2px;
        top: 0;
      }
    }
  }
`

export default FeatureCard
