const appLinksFromSlug = {
  documents: [
    {
      id: "overview",
      text: "Overview",
      scroll: "/documents#overview",
    },
    {
      id: "pricing",
      text: "Pricing",
      scroll: "/documents#pricing",
    },
    {
      id: "all_features",
      text: "All Features",
      to: "/documents/features",
    },
    {
      id: "comparison",
      text: "Comparison",
      to: "/documents/compare",
    },
    {
      id: "documentation",
      text: "Documentation",
      href: "https://kb.opero.com/documents",
    },
  ],
  fax: [
    {
      id: "overview",
      text: "Overview",
      scroll: "/fax#overview",
    },
    {
      id: "pricing",
      text: "Pricing",
      scroll: "/fax#pricing",
    },
    {
      id: "all_features",
      text: "All Features",
      to: "/fax/features",
    },
    {
      id: "comparison",
      text: "Comparison",
      to: "/fax/compare",
    },
    {
      id: "documentation",
      text: "Documentation",
      href: "https://kb.opero.com/fax/",
    },
  ],
  signature: [
    {
      id: "overview",
      text: "Overview",
      scroll: "/signature#overview",
    },
    {
      id: "pricing",
      text: "Pricing",
      scroll: "/signature#pricing",
    },
    {
      id: "all_features",
      text: "All Features",
      to: "/signature/features",
    },
    {
      id: "comparison",
      text: "Comparison",
      to: "/signature/compare",
    },
    {
      id: "documentation",
      text: "Documentation",
      href: "https://kb.opero.com/signature",
    },
  ],
  ship: [
    {
      id: "overview",
      text: "Overview",
      scroll: "/ship#overview",
    },
    {
      id: "pricing",
      text: "Pricing",
      scroll: "/ship#pricing",
    },
    {
      id: "all_features",
      text: "All Features",
      to: "/ship/features",
    },
    {
      id: "comparison",
      text: "Comparison",
      to: "/ship/compare",
    },
    {
      id: "documentation",
      text: "Documentation",
      href: "https://kb.opero.com/ship",
    },
  ],
  payments: [
    {
      id: "overview",
      text: "Overview",
      scroll: "/payments#overview",
    },
    {
      id: "pricing",
      text: "Pricing",
      scroll: "/payments#pricing",
    },
    // {
    //   id: "all_features",
    //   text: "All Features",
    //   to: "/payments/features",
    // },
    {
      id: "comparison",
      text: "Comparison",
      to: "/payments/compare",
    },
    {
      id: "documentation",
      text: "Documentation",
      href: "https://kb.opero.com/payments",
    },
  ],
  forms: [
    {
      id: "overview",
      text: "Overview",
      scroll: "/forms#overview",
    },
    {
      id: "pricing",
      text: "Pricing",
      scroll: "/forms#pricing",
    },
    // {
    //   id: "all_features",
    //   text: "All Features",
    //   to: "/forms/features",
    // },
    {
      id: "comparison",
      text: "Comparison",
      to: "/forms/compare",
    },
    {
      id: "documentation",
      text: "Documentation",
      href: "https://kb.opero.com/forms",
    },
  ],
}

export default appLinksFromSlug
