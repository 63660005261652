import React, { useCallback } from "react"
import styled from "styled-components"
import map from "lodash/map"

import { Accordion, TextGroup } from "components/common"
import BasicCard from "./BasicCard"

const AccordionCard = ({ title, valueProps }) => {
  const renderAccordions = useCallback(
    () => map(valueProps, (vp) => <Accordion key={vp.id} {...vp} />),
    [valueProps]
  )

  return (
    <StyledAccordionCard>
      <TextGroup className="cardTitle" title={title} titleAs="h3" />

      <BasicCard>{renderAccordions()}</BasicCard>
    </StyledAccordionCard>
  )
}

const StyledAccordionCard = styled.div`
  .basicCard {
    padding: var(--sp-16) var(--sp-32);
  }

  .cardTitle {
    --tg-title-color: var(--blue);
    margin-bottom: var(--sp-16);
  }
`

export default AccordionCard
