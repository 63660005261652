import React from "react"
import styled from "styled-components"
import { useLocation } from "@reach/router"
import filter from "lodash/filter"
import { appLinksFromSlug } from "utils"

import { NavAccordion } from "components/navigation"

const AppAccordionNav = ({ slug, close }) => {
  const { pathname } = useLocation()
  const links = appLinksFromSlug[slug]

  const textByPathname = {
    [pathname]: { filter: "overview", text: "Overview" },
    [`/${slug}/features/`]: { filter: "all_features", text: "All Features" },
    [`/${slug}/compare/`]: { filter: "comparison", text: "Comparison" },
  }

  const title = textByPathname[pathname].text

  const appLinks = filter(
    links,
    (link) => textByPathname[pathname].filter !== link.id
  )

  return (
    <StyledAppAccordionNav slug={slug}>
      <NavAccordion title={title} links={appLinks} close={close} />
    </StyledAppAccordionNav>
  )
}

const StyledAppAccordionNav = styled.div`
  --accordion-content-background: var(--card-shade_2);
  --accordion-title-background: var(--card-shade_2);
  /* --accordion-padding: var(--sp-16) var(--sp-24); */

  --nav-accordion-divider-color: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0.3) 85%,
    rgba(255, 255, 255, 0) 100%
  );

  /* .acordion__title {
    text: normal;
  } */
`

export default AppAccordionNav
