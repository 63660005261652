import React from "react"
import styled from "styled-components"
import classNames from "classnames"
import { gradientBorder } from "utils"

const BasicCard = ({ children, className }) => {
  return (
    <StyledBasicCard className={classNames("basicCard", className)}>
      {children}
    </StyledBasicCard>
  )
}

const StyledBasicCard = styled.div`
  padding: var(--card-padding);
  border-radius: var(--card-radius);
  box-shadow: var(--card-shadow);

  transition: all 0.1s ease-in-out;
  transition-property: box-shadow background, background-image, border;
  will-change: box-shadow, background, background-image, border;

  ${gradientBorder({
    background: "var(--card-background)",
    gradient: "var(--card-border)",
  })};
`

export default BasicCard
