import React, { useCallback } from "react"
import { Link as GLink } from "gatsby"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import styled from "styled-components"
// import { OutboundLink } from "gatsby-plugin-google-gtag"

import { useTracking } from "utils"

// import OutboundLink from "components/common/OutboundLink"

import Arrow from "images/common/arrow.inline.svg"

/**
 * Link
 * @param {object} props
 * @param {string} props.href
 * @param {string} props.to
 * @param {string} props.activeClassName
 * @param {boolean} props.arrow
 * @param {boolean} props.underline
 * @param {object} props.track
 * @param {String} props.track.category
 *
 * @example
 * <Link to="/">Test</Button>
 */

const Link = ({
  to,
  href,
  scroll,
  underline,
  arrow,
  children,
  activeClassName,
  track,
  notLink,
  onClick,
  ...other
}) => {
  const [trackInternal] = useTracking({ ...track })
  const handleClick = useCallback(() => {
    trackInternal()
    if (onClick) {
      onClick()
    }
  }, [trackInternal, onClick])

  const renderLink = useCallback(() => {
    if (notLink) {
      return (
        <StyledLinkSpan className="notLink" {...other}>
          {children}
        </StyledLinkSpan>
      )
    }

    if (to) {
      const hasSlash = to.match(/\/+$/)
      const toWithSlash = hasSlash ? to : `${to}/`

      return (
        <GLink
          to={toWithSlash}
          activeClassName={activeClassName}
          {...other}
          onClick={handleClick}
        >
          {children}
        </GLink>
      )
    }

    if (scroll) {
      return (
        <span
          role="button"
          onClick={handleClick}
          tabIndex={0}
          onKeyDown={handleClick}
        >
          <AnchorLink to={scroll} activeClassName={activeClassName} {...other}>
            {children}
          </AnchorLink>
        </span>
      )
    }

    return (
      <a className="outbound" href={href} track={track} {...other}>
        {children}
      </a>
    )
  }, [
    to,
    href,
    children,
    other,
    activeClassName,
    track,
    notLink,
    scroll,
    handleClick,
  ])

  if (arrow) {
    return (
      <StyledLinkSpan underline={underline}>
        {renderLink()}
        <Arrow alt="svg arrow" className="arrow" />
      </StyledLinkSpan>
    )
  }

  return renderLink()
}

const StyledLinkSpan = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: ${({ underline }) => (underline ? "underline" : "none")};

  &.notLink {
    font-family: var(--body-font-family);
    font-weight: 600;
    letter-spacing: 0.3em;
    /* 14px */
    font-size: 0.875rem;
    line-height: 25px;
  }

  .arrow {
    margin-left: var(--sp-8);
    transition: 0.1s all ease-in-out;
    transition-property: opacity, transform;
  }

  &:hover {
    opacity: 0.8;
    .arrow {
      transform: translateX(var(--sp-8));
    }
  }
`

Link.defaultProps = {
  to: "",
  href: "",
  arrow: false,
  underline: false,
}

export default Link
