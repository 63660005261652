import React from "react"
import styled from "styled-components"
// import { motion } from "framer-motion"
import classNames from "classnames"
import map from "lodash/map"

import OperoLogo from "src/images/nav/nav_opero_logo.inline.svg"
// import OperoLogoUkraine from "src/images/nav/ukraine/opero_logo_horzontal_ukraine.inline.svg"

import { Link, IsSticky, Button, HContainer } from "components/common"

import {
  device,
  appTextColors,
  appColors,
  appTextFromSlug,
  appLinksFromSlug,
} from "utils"

const AppStickyNav = ({ slug, salesforceAppexchangeUrl, disabled }) => {
  const links = appLinksFromSlug[slug]

  const renderNav = () =>
    map(links, ({ id, text, to, href, scroll }) => (
      <li key={id}>
        <Link to={to} href={href} scroll={scroll} activeClassName="active">
          {text}
        </Link>
      </li>
    ))

  return (
    <>
      <Spacer />
      <IsSticky>
        {({ isSticky }) => {
          return (
            <StyledAppStickyNav
              className={classNames({ isSticky })}
              slug={slug}
            >
              <HContainer>
                <LogoWrap className={classNames({ isSticky })}>
                  <Link to="/">
                    <OperoLogo className="logo" />
                    {/* <OperoLogoUkraine className="logo" /> */}
                  </Link>
                  {slug && (
                    <h5>
                      {appTextFromSlug[slug]}
                      <strong className={slug}>.</strong>
                    </h5>
                  )}
                </LogoWrap>
                <ul>{renderNav()}</ul>
                {!disabled && (
                  <Link href={salesforceAppexchangeUrl} target="_blank">
                    <Button id="nav_cta" className={classNames({ isSticky })}>
                      TRY IT NOW
                    </Button>
                  </Link>
                )}
              </HContainer>
            </StyledAppStickyNav>
          )
        }}
      </IsSticky>
    </>
  )
}

const Spacer = styled.div`
  display: none;

  @media ${device.laptop} {
    padding-top: var(--sp-24);
    background: var(--section-background-color);
    display: block;
  }
`

// const StyledAppStickyNav = styled(motion.header)`
const StyledAppStickyNav = styled.header`
  background: var(--section-background-color);
  padding: var(--sp-24) 0;
  transition: all 0.2s ease-in-out;
  transition-property: box-shadow, background;
  will-change: box-shadow, background;
  position: absolute;

  left: 0;
  right: 0;
  width: 100%;
  z-index: 5;
  top: var(--sp-120);
  display: none;

  @media ${device.laptop} {
    display: block;
  }

  &.isSticky {
    position: fixed;
    top: 0;
    box-shadow: var(--card-shadow);

    /*  */
    background: var(--darkBG);
    --tg-title-color: var(--highlight_1);
    --tg-subtitle-color: var(--highlight_1);
    --nav-sticky-link-color: var(--highlight_1);
  }

  .max {
    --section-align: center;
    padding: 0;
    flex-direction: row;
    justify-content: space-between;
  }

  ul {
    display: grid;
    justify-content: center;
    grid-template-columns: repeat(5, auto);
    grid-gap: var(--sp-48);

    li {
      a {
        font-family: var(--body-font-family);
        font-weight: 400;
        font-size: 0.875rem;
        line-height: 22px;
        color: var(--nav-sticky-link-color);
        transition: opacity 0.2s ease-in-out;
        will-change: opacity;

        &.active {
          color: ${({ slug }) => appColors[slug].light};
        }

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }

  #nav_cta {
    --button-background: ${({ slug }) => appColors[slug].dark};
    --button-padding: var(--sp-16);
    font-size: 0.75rem;

    visibility: hidden;
    opacity: 0;
    transition: all 0.2s ease-in-out;
    transition-property: visbility opacity;
    will-change: visibility opacity;

    &.isSticky {
      visibility: visible;
      opacity: 1;
      &:hover {
        opacity: 0.8;
      }
    }
  }
`

const LogoWrap = styled.div`
  display: flex;
  align-items: center;

  visibility: hidden;
  opacity: 0;
  transition: all 0.2s ease-in-out;
  transition-property: visbility opacity;
  will-change: visibility opacity;

  &.isSticky {
    visibility: visible;
    opacity: 1;
  }

  .logo {
    height: 20px;
    width: auto;
  }

  a {
    display: flex;
    align-items: center;
  }

  h5 {
    border-left: 1px solid #53565d;
    padding-left: var(--sp-24);
    margin-left: var(--sp-24);
    font-size: 1rem;
    line-height: 1;
    padding-bottom: 2px;
    strong {
      ${appTextColors};
    }
  }
`

export default AppStickyNav
