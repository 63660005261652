import React from "react"
import styled from "styled-components"
import map from "lodash/map"

import { ToolTip, BasicCard, Link } from "components/common"
import { appTextColors } from "utils"

import options from "utils/global/appNavigation"

const AppsToolTip = () => {
  const renderNav = () =>
    map(options, ({ id, text, to, icon: Icon }) => (
      <li key={id}>
        <Link to={to}>
          <Icon className="app-icon" />
          <h5 className="text">
            {text}
            <strong className={id}>.</strong>
          </h5>
        </Link>
      </li>
    ))

  return (
    <StyledAppsToolTip>
      <ToolTip className="nav-app" left toggle={() => <li>Apps</li>}>
        <BasicCard>
          <h4 className="header">Apps</h4>

          <ul>{renderNav()}</ul>
        </BasicCard>
      </ToolTip>
    </StyledAppsToolTip>
  )
}

const StyledAppsToolTip = styled.div`
  --tooltip-carrot-offset: 32px;
  --tooltip-dropdown-offset: 32px;
  --toolTip-width: 265px;

  .basicCard {
    background-image: none;
    background: var(--card-shade_1);
    border: 2px solid #42444d;
    padding: 0;
    padding-top: var(--sp-32);
    padding-bottom: var(--sp-16);

    .header {
      font-size: 0.75rem;
      text-transform: uppercase;
      padding: 0 var(--sp-32);
    }

    ul {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0;
      margin-top: var(--sp-16);

      li {
        position: relative;

        &:after {
          content: "";
          position: absolute;
          left: var(--sp-32);
          right: 0;
          bottom: -1px;
          height: 2px;
          background: linear-gradient(
            270deg,
            #505156 84.86%,
            rgba(80, 81, 86, 0) 100%
          );
        }

        &:last-child {
          &:after {
            display: none;
          }
        }

        &:hover {
          .app-icon {
            opacity: 1;
          }
          a {
            opacity: 1;

            .text {
              opacity: 0.8;
            }
          }
        }

        a {
          display: block;
          padding: var(--sp-8) var(--sp-32);
          /* opacity: 0.3; */
          transition: opacity 0.2s ease-in-out;
          will-change: opacity;
          .app-icon {
            position: absolute;
            left: -24px;
            top: 50%;
            transform: translateY(-50%);
            box-shadow: var(--card-shadow);
            border-radius: 50%;
            opacity: 0;
            transition: opacity 0.2s ease-in-out;
            will-change: opacity;
          }

          .text {
            strong {
              ${appTextColors};
            }
          }
        }
      }
    }
  }

  .bottom {
    border-top: 2px solid #42444d;
    padding: var(--sp-32);

    margin-top: var(--sp-24);
    background: var(--card-shade_2);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-16);
    border-bottom-right-radius: var(--card-radius);
    border-bottom-left-radius: var(--card-radius);

    a {
      transition: opacity 0.2s ease-in-out;

      &:hover {
        opacity: 0.5;
      }

      p {
        font-size: 1rem;
        line-height: 16px;
      }
    }
  }
`

export default AppsToolTip
