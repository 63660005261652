import React from "react"
import styled from "styled-components"

import { Cta, CardWithHeader, TextGroup, Media } from "components/common"

import { matrixBackgroundStyle, device, richTextBody, useRichText } from "utils"

const PricingCard = (props) => {
  const {
    title,
    subtitle,
    cta,
    perks,
    starRating,
    ratingDescription,
    backgroundImage,
    salesforceAppexchangeUrl,
    disabled,
  } = props

  const ctaWithUrl = { ...cta, url: salesforceAppexchangeUrl }

  const renderPerksBody = useRichText({ text: perks })

  const renderPerks = () => (
    <CardWithHeader
      header={
        starRating && ratingDescription
          ? () => (
              <>
                <Media media={{ desktop: starRating }} />
                <TextGroup subtitle={ratingDescription} />
              </>
            )
          : null
      }
      body={() => renderPerksBody}
    />
  )

  return (
    <StyledPricingCard>
      <div className="max">
        <div className="tgContainer">
          <TextGroup title={title} subtitle={subtitle} />
          {!disabled && (
            <Cta ctas={[ctaWithUrl]} theme={[{ target: "_blank" }]} />
          )}
        </div>
        <div className="perkContainer">{renderPerks()}</div>
      </div>
      <Media className="backgroundImage" media={backgroundImage} />
    </StyledPricingCard>
  )
}

const StyledPricingCard = styled.div`
  position: relative;

  .max {
    display: flex;
    flex-direction: column;
    align-items: center;

    position: relative;
    z-index: 2;
  }

  .tgContainer {
    display: flex;
    flex-direction: column;
    align-items: center;

    /* justify-content: space-between; */
    justify-content: center;
    width: 100%;
    --tg-grid-gap: var(--sp-8);

    @media ${device.laptop} {
      flex-direction: row;
    }

    .tgWrap {
      @media ${device.laptop} {
        margin-right: var(--sp-48);
      }
      .title {
        font-family: var(--body-font-family);
        .bold {
          font-family: var(--title-font-family);
          font-size: 3.25rem;
          line-height: 60px;

          @media ${device.laptop} {
            font-size: 6.375rem;
            line-height: 107px;
          }
        }
      }
      .subtitle {
        font-weight: 400;
        text-align: center;
        margin-bottom: var(--sp-16);

        @media ${device.laptop} {
          margin-bottom: none;
          text-align: left;
        }
      }
    }
  }

  .perkContainer {
    margin: 0 auto;
    margin-top: var(--sp-56);
    max-width: 610px;
    .header {
      display: flex;
      flex-direction: column;
      align-items: center;
      --tg-grid-gap: none;

      @media ${device.laptop} {
        flex-direction: row;
      }

      img {
        @media ${device.laptop} {
          margin-right: var(--sp-4);
        }
      }

      .subtitle {
        font-size: 0.875rem;
        line-height: 22px;
        text-align: center;
        /* max-width: 320px; */

        @media ${device.laptop} {
          text-align: left;
        }

        .bold {
          font-weight: 700;
        }
      }
    }

    .body {
      ${richTextBody};
      p {
        margin-bottom: 0;
      }
      ul {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: var(--sp-16);
        padding-bottom: 0;
        margin-bottom: 0;
        /* padding-left: 40px; */
      }
    }
  }

  .backgroundImage {
    ${matrixBackgroundStyle};
  }
`

export default PricingCard
