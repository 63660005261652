import React from "react"
import styled from "styled-components"

import map from "lodash/map"

import { Link } from "components/common"

import { device, footerLinks } from "utils"

import SFLogo from "src/images/nav/sf_logo_footer.inline.svg"
// import OperoLogo from "src/images/nav/nav_opero_logo.inline.svg"
import { StaticImage } from "gatsby-plugin-image"

const Footer = () => {
  // const { dot_matrix } = useStaticQuery(FOOTER_QUERY)

  const renderCol = (links, title) => {
    const renderGrid = () =>
      map(links, ({ text, href, to }) => (
        <li key={text}>
          <Link className="link" to={to} href={href}>
            {text}
          </Link>
        </li>
      ))

    return (
      <StyledLinkCol>
        <p className="col-title">{title}</p>
        {renderGrid()}
      </StyledLinkCol>
    )
  }

  return (
    <StyledFooter>
      <StyledLinksCol>
        <div className="first">
          <Link to="/" className="opero_logo">
            {/* <OperoLogo /> */}
            <StaticImage
              src="../../images/nav/concord/OperoCTCompanyWhite.png"
              alt="Opero a Concord technologies company"
              width={120}
            />
          </Link>
          <SFLogo className="sf desktop" />
        </div>
        {renderCol(footerLinks.apps, "Apps")}
        {renderCol(footerLinks.resources, "Resources")}
        {renderCol(footerLinks.company, "Company")}
      </StyledLinksCol>

      {/* <BackgroundImage>
        <Media media={dot_matrix} />
      </BackgroundImage> */}
    </StyledFooter>
  )
}

const StyledFooter = styled.footer`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: var(--sp-section) var(--sp-section-lr) var(--sp-section);

  @media ${device.laptop} {
    align-items: center;
  }

  .first {
    display: flex;
    flex-direction: column;
    grid-gap: var(--sp-16);

    align-items: flex-start;

    .cc {
      display: grid;
      grid-gap: var(--sp-8);
      p {
        font-size: 0.625rem;
        line-height: 12px;
      }
    }
  }
`

const StyledLinksCol = styled.div`
  display: grid;
  justify-content: center;
  grid-gap: var(--sp-56);
  grid-template-columns: 1fr;
  position: relative;
  z-index: 2;

  @media ${device.laptop} {
    grid-gap: var(--sp-120);

    grid-template-columns: repeat(4, auto);
  }
`
const StyledLinkCol = styled.ul`
  display: grid;
  grid-template-columns: 1fr;

  grid-auto-rows: min-content;
  grid-gap: var(--sp-16);

  .col-title {
    font-weight: 600;
    font-size: 1.25rem;
    line-height: 24px;
  }
  li {
    .link {
      font-weight: 400;
      font-size: 0.875rem;
      line-height: 1;
      transition: opacity 0.2s ease-in-out;

      @media ${device.laptop} {
        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`

// const BackgroundImage = styled.div`
//   z-index: 1;
//   ${matrixBackgroundStyle};
//   height: 100%;
//   width: 100%;

//   .fileImage {
//     height: 100%;
//     width: 100%;
//     img {
//       height: 100%;
//       width: 100%;
//       object-fit: cover !important;

//       @media ${device.laptop} {
//         object-fit: contain !important;
//       }
//     }
//   }
// `

// const FOOTER_QUERY = graphql`
//   {
//     dot_matrix: contentfulMedia(mediaId: { eq: "footer_dot_matrix" }) {
//       ...Media
//     }
//   }
// `

export default Footer
