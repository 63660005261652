import React, { useState } from "react"
import { Waypoint } from "react-waypoint"

const IsSticky = ({ children }) => {
  const [isSticky, setIsSticky] = useState(false)

  const onPositionChange = ({ currentPosition, previousPosition }) => {
    if (currentPosition === "above") {
      setIsSticky(true)
    }

    if (previousPosition === "above" && currentPosition === "inside") {
      setIsSticky(false)
    }
  }

  return (
    <>
      <Waypoint onPositionChange={onPositionChange} />
      {children({ isSticky })}
    </>
  )
}

export default IsSticky
