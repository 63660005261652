import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { motion, AnimatePresence } from "framer-motion"

import classNames from "classnames"

import { useHover } from "src/utils"

import Carrot from "src/images/nav/menu_carrot.inline.svg"

// usage

// <ToolTip left toggle={() => <li className="link">Apps</li>}>
//  <div>dropdown here</div>
// </ToolTip>

const variants = {
  hidden: {
    opacity: 0,
    y: 20,
  },
  visible: {
    opacity: 1,
    y: 0,
  },
  exit: {
    opacity: 0,
    y: 20,
  },
}

const ToolTip = ({
  id,
  toggle: Toggle,
  className,
  children,
  left,
  right,
  isHovered,
}) => {
  const [toggleRef, toggleIsHovered] = useHover(isHovered)

  const [hoverDropdownRef, dropdownIsHovered] = useHover(isHovered)
  const [show, setShow] = useState(false)

  useEffect(() => {
    let timeout
    const setupTimeout = () => {
      timeout = setTimeout(() => setShow(false), 100)
    }

    if (toggleIsHovered || dropdownIsHovered) {
      clearTimeout(timeout)
      setShow(true)
    } else {
      setupTimeout()
    }

    return () => clearTimeout(timeout)
  }, [toggleIsHovered, dropdownIsHovered])

  return (
    <StyledToolTip className={classNames(className, { left, right })}>
      <div className="toggleContainer" ref={toggleRef}>
        <Toggle />
      </div>
      <AnimatePresence>
        {show && (
          <div ref={hoverDropdownRef}>
            <Dropdown
              className="dropdown"
              variants={variants}
              initial="hidden"
              animate="visible"
              exit="exit"
              key={id}
            >
              <Carrot className="carrot" />
              {children}
            </Dropdown>
          </div>
        )}
      </AnimatePresence>
    </StyledToolTip>
  )
}

// Variables ------------------
// --tooltip-carrot-offset
// --toolTip-width
// --tooltip-dropdown-offset
// ------------------

const StyledToolTip = styled.div`
  position: relative;

  .toggleContainer {
    cursor: default;
  }

  &.left {
    .dropdown {
      left: calc(-1 * var(--tooltip-dropdown-offset));
    }
    .carrot {
      left: var(--tooltip-carrot-offset);
    }
  }
  &.right {
    .dropdown {
      right: calc(-1 * var(--tooltip-dropdown-offset));
    }
    .carrot {
      right: var(--tooltip-carrot-offset);
    }
  }

  .carrot {
    position: absolute;
    top: -13px;
    z-index: 20;
  }
`

const Dropdown = styled(motion.nav)`
  width: var(--toolTip-width);
  margin-top: 44px;
  position: absolute;
  top: 0;
  z-index: 20;
`

ToolTip.defaultProps = {
  left: false,
  right: false,
  isHovered: false,
}

export default ToolTip
