import styled from "styled-components"
import { appColors, device } from "src/utils"

const AppCompareTableStyles = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  border-radius: var(--card-radius);
  box-shadow: var(--card-shadow);
  background: var(--card-shade_1);
  border: var(--table-border);
  align-items: center;
  justify-content: center;

  .row {
    display: grid;
    grid-template-columns: 25% 40% 1fr;
    border-bottom: var(--table-border);

    @media ${device.laptop} {
      grid-template-columns: 25% 30% 1fr;
    }

    &:last-child {
      border-bottom: none;
    }

    &.hide_company,
    &.hide_price,
    &.hide_interval {
      border-top: none;
      border-bottom: none;

      .cell {
        padding: 0px;
      }
    }

    &.hide_company {
      grid-row-start: 1;
      .cell {
        padding: var(--sp-16);

        @media ${device.laptop} {
          padding: var(--sp-24);
        }
      }
    }
    &.hide_price {
      grid-row-start: 2;
      .cell {
        line-height: 1;
        font-family: var(--title-font-family);
        font-size: 2.125rem;
      }
    }

    &.hide_interval {
      grid-row-start: 3;
      border-bottom: var(--table-border);
      .cell {
        font-weight: 400;
        padding-bottom: var(--sp-24);
      }
    }
  }
`

const Cell = styled.div`
  text-align: center;
  font-family: var(--body-font-family);
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 22px;
  padding: var(--sp-12);

  @media ${device.mobileM} {
    font-size: 0.875rem;
  }
  @media ${device.mobileL} {
    padding: var(--sp-16);
  }
  @media ${device.laptop} {
    padding: var(--sp-16) var(--sp-24);
  }

  /* border..... */
  border-right: var(--table-border);

  &:first-child {
    border-right: none;
  }

  &.feature {
    justify-content: flex-start;
    text-align: left;
    font-family: var(--body-font-family);
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 16px;

    @media ${device.laptop} {
      font-size: 0.83rem;
      line-height: 22px;
    }
  }

  &.opero {
    border-right: none;
    background: ${({ slug }) => appColors[slug].darker};
    position: relative;
    display: flex;

    .col_hat {
      position: absolute;
      left: 0;
      right: 0;
      background: ${({ slug }) => appColors[slug].darker};
      text-align: center;
      border-top-right-radius: var(--card-radius);
      border-top-left-radius: var(--card-radius);

      height: 45px;
      top: -44px;

      @media ${device.laptop} {
        height: 65px;
        top: -64px;
      }

      .logoWrap {
        position: relative;
        text-align: center;

        top: -30px;

        @media ${device.mobileL} {
          top: -40px;
        }
        @media ${device.laptop} {
          top: -56px;
        }

        .app_logo {
          margin: 0 auto;
          border-radius: 50%;
          box-shadow: var(--card-shadow);
          width: 65px;
          height: 65px;

          @media ${device.mobileL} {
            width: 80px;
            height: 80px;
          }

          @media ${device.laptop} {
            width: 112px;
            height: 112px;
          }
        }
      }
    }
    .app_text {
      width: 100%;
      max-width: 219px;
    }
  }
`

export { Cell, AppCompareTableStyles }
