import React, { useState, useRef } from "react"
import styled from "styled-components"

import classNames from "classnames"

import { TextGroup } from "src/components/common"

import Carrot from "src/images/common/accordion_carrot.inline.svg"

const BasicAccordion = ({ title, titleAs, children }) => {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")

  const content = useRef(null)

  function toggleAccordion() {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    )
  }

  return (
    <Accordion>
      <button
        className={classNames("accordionTitleWrap", { setActive })}
        onClick={toggleAccordion}
      >
        <TextGroup
          titleAs={titleAs}
          title={title}
          className="accordion__title"
        />

        <span
          className={classNames("toggle-icon", {
            open: setActive,
            close: !setActive,
          })}
        >
          <Carrot />
        </span>
      </button>
      <div
        ref={content}
        style={{ maxHeight: `${setHeight}` }}
        className="accordion__content"
      >
        {children}
      </div>
    </Accordion>
  )
}

const Accordion = styled.div`
  display: flex;
  flex-direction: column;

  .toggle-icon {
    font-size: 14px;
    font-family: var(--title-font-family);
    /* color: #474c69; */
    color: var(--highlight_1);
    transition: 0.1s all ease-in-out;
    transition-property: transform, color;
    will-change: transform, color;

    &.open {
      transform: rotate(90deg);
      color: var(--highlight_1);
    }
  }

  /* Style the buttons that are used to open and close the accordion panel */
  .accordionTitleWrap {
    color: var(--primary-font-color);
    cursor: pointer;
    padding: var(--accordion-padding);
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: none;
    outline: none;
    background: var(--accordion-title-background);
  }

  .accordion__title {
    text-align: left;
    .title {
      margin-bottom: 0;
    }
  }

  /* Style the accordion chevron icon */
  .accordion__icon {
    margin-left: auto;
    transition: transform 0.2s ease;
  }

  /* Style to rotate icon when state is active */
  .rotate {
    transform: rotate(90deg);
  }

  /* Style the accordion content panel. Note: hidden by default */
  .accordion__content {
    text-align: left;
    overflow: hidden;
    transition: max-height 0.2s ease;
    background: var(--accordion-content-background);

    box-shadow: 0px 40px 48px 0px rgba(23, 23, 23, 0.15);
  }
`

BasicAccordion.defaultProps = {
  titleAs: "pSmall",
}

export default React.memo(BasicAccordion)
