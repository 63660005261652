import React from "react"
import styled from "styled-components"

import { BasicCard } from "components/common"

const CardWithHeader = ({ body: Body, header: Header }) => {
  return (
    <StyledCardWithHeader>
      <BasicCard>
        {Header && (
          <div className="header">
            <Header />
          </div>
        )}
        <div className="body">
          <Body />
        </div>
      </BasicCard>
    </StyledCardWithHeader>
  )
}

const StyledCardWithHeader = styled.div`
  .basicCard {
    padding: 0;
  }

  .header {
    background: var(--card-shade_2);
    padding: var(--card-padding);
    position: relative;
    border-top-right-radius: var(--card-radius);
    border-top-left-radius: var(--card-radius);

    &::after {
      content: "";
      height: 2px;
      width: 100%;
      background: linear-gradient(
        90deg,
        rgba(199, 205, 226, 0.24) 0%,
        rgba(190, 196, 216, 0) 100%
      );

      position: absolute;
      bottom: 0;
      left: 0;
    }
  }

  .body {
    padding: var(--card-padding);
  }
`

export default CardWithHeader
