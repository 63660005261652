import React from "react"
import styled from "styled-components"

const CodeBlock = ({ content }) => {
  function createMarkup() {
    return { __html: content.childMarkdownRemark.html }
  }
  return (
    <>
      &nbsp;
      <StyledCodeBlock
        className="code-block"
        dangerouslySetInnerHTML={createMarkup()}
      />
    </>
  )
}

const StyledCodeBlock = styled.span`
  display: inline-block;

  p {
    display: inline-block;
  }
`

export default CodeBlock
