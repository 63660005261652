const footerLinks = {
  apps: [
    {
      to: "/documents",
      text: "Documents",
    },
    {
      to: "/signature",
      text: "Signature",
    },
    {
      to: "/fax",
      text: "Fax",
    },
    // {
    //   to: "/ship",
    //   text: "Ship",
    // },
    // {
    //   to: "/payments",
    //   text: "Payments",
    // },
    // {
    //   to: "/forms",
    //   text: "Forms",
    // },
  ],
  resources: [
    {
      to: "/knowledge",
      text: "Knowledge Base",
    },
    {
      to: "/partners",
      text: "Partners",
    },
    {
      to: "/become-partner",
      text: "Become a Partner",
    },
    // {
    //   to: "/case-study",
    //   text: "Case Studies",
    // },
    // {
    //   to: "/blog",
    //   text: "Blog",
    // },
  ],
  company: [
    {
      to: "/about",
      text: "About",
    },
    {
      to: "/contact",
      text: "Contact Us",
    },
    {
      to: "/privacy-policy",
      text: "Privacy Policy",
    },
  ],
}

export default footerLinks
