import { css } from "styled-components"

const gradientText = (background) => css`
  background: ${background};
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`

export default gradientText
