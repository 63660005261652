import React from "react"
import styled from "styled-components"
import map from "lodash/map"

import { ToolTip, BasicCard, Link } from "components/common"

import { resourcesNavLinks } from "src/utils/global"

const ResourcesToolTip = () => {
  const renderNav = () =>
    map(resourcesNavLinks, ({ text, to, href }) => (
      <li key={text}>
        <Link to={to} href={href}>
          <p>{text}</p>
        </Link>
      </li>
    ))

  return (
    <StyledResourcesToolTip>
      <ToolTip right className="nav-support" toggle={() => <li>Resources</li>}>
        <BasicCard>
          <h4 className="header">Resources</h4>

          <ul>{renderNav()}</ul>
          <div className="bottom">
            <Link to="/contact">
              <h4>CONTACT US</h4>
            </Link>
          </div>
        </BasicCard>
      </ToolTip>
    </StyledResourcesToolTip>
  )
}

const StyledResourcesToolTip = styled.div`
  --tooltip-carrot-offset: 32px;
  /* --tooltip-dropdown-offset: 32px; */
  --toolTip-width: 263px;

  .basicCard {
    background-image: none;
    background: var(--card-shade_1);
    border: 2px solid #42444d;
    padding: 0;
    padding-top: var(--sp-32);

    .header {
      font-size: 0.75rem;
      text-transform: uppercase;
      padding: 0 var(--sp-32);
    }

    ul {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 0;
      margin-top: var(--sp-16);

      li {
        a {
          display: block;
          padding: var(--sp-8) var(--sp-32);

          transition: opacity 0.2s ease-in-out;
          will-change: opacity;

          &:hover {
            opacity: 0.5;
          }
          p {
            font-size: 1rem;
            line-height: 16px;
          }
        }
      }
    }
  }

  .bottom {
    border-top: 2px solid #42444d;
    padding: var(--sp-32);
    border-bottom-right-radius: var(--card-radius);
    border-bottom-left-radius: var(--card-radius);
    margin-top: var(--sp-24);
    background: var(--card-shade_2);

    text-align: center;

    a {
      display: inline-block;
      transition: opacity 0.2s ease-in-out;
      will-change: opacity;

      &:hover {
        opacity: 0.5;
      }
    }
  }
`

export default ResourcesToolTip
