import { css } from "styled-components"

const gradientBorder = ({ background, gradient }) => css`
  border: 2px solid transparent;
  background-image: linear-gradient(${background}, ${background}), ${gradient};
  background-origin: border-box;
  background-clip: padding-box, border-box;
`
// ${gradientBorder({
//   background:
//     "180deg, #1C2233 0%, rgba(25, 31, 47, 0.897053) 54.05%, rgba(21, 26, 39, 0.57) 100%",
//   gradient: "180deg,#c183df -2.13%,rgba(75, 82, 103, 0) 96.19%",
// })};

export default gradientBorder

//   background-image: linear-gradient(black, black),
//     linear-gradient(90deg, #ff04c8 -3.01%, #fda700 137.77%);
