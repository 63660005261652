import React from "react"

import get from "lodash/get"
import styled from "styled-components"
import { richTextBody } from "src/utils"

const Markdown = ({ body }) => {
  const html = get(body, "childMarkdownRemark.html")

  return (
    <StyledMarkdown
      className="markdown_body"
      dangerouslySetInnerHTML={{ __html: html }}
    />
  )
}

const StyledMarkdown = styled.div`
  ${richTextBody};
`

export default Markdown
