import { css } from "styled-components"

export default css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 1;
  text-align: center;

  img {
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
  }
`
