import { css } from "styled-components"

const appTextColors = css`
  &.documents {
    color: var(--blue);
  }
  &.fax {
    color: var(--green);
  }
  &.signature {
    color: var(--red);
  }
  &.ship {
    color: var(--purple);
  }
  &.ship {
    color: var(--purple);
  }
  &.payments {
    color: var(--teal);
  }
  &.forms {
    color: var(--yellow);
  }
`

export default appTextColors
