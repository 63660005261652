import React from "react"
import map from "lodash/map"
import styled from "styled-components"
import PropTypes from "prop-types"

import Button from "./Button"
import Link from "./Link"

import TextGroup from "./TextGroup"

import { device } from "utils"
// import { render } from "react-dom"

/**
 * @description Contentful CTA for Templates
 * @typedef {{
 *  ctas: [{text: String, url: String to: String, tracking: {}}]
 *  theme: [{theme: 'default' | 'black', small: false, isLink: false, target: '_blank'}]
 * }} Props
 *
 * @type React.FunctionComponent<Props>
 */

const CTA = ({ ctas, theme, notLink, arrow, ...other }) => {
  return (
    <StyledCTA ctasLength={ctas && ctas.length} {...other} className="cta-wrap">
      {map(ctas, (props, idx) => {
        return (
          <CtaGroup className="cta-group" hasDetails={props.details} key={idx}>
            {RenderCta({ ...props, theme, arrow, notLink }, idx)}

            {props.details && (
              <TextGroup className="cta-details" subtitle={props.details} />
            )}
          </CtaGroup>
        )
      })}
    </StyledCTA>
  )
}

const RenderCta = (
  { text, id, to, href, url, tracking, theme, arrow, notLink },
  idx
) => {
  const externalLink = url || href

  const btnTheme = theme.length && theme[idx]
  if (btnTheme.isLink) {
    return (
      <Link
        notLink={notLink}
        to={to}
        href={externalLink}
        key={id}
        arrow={arrow}
      >
        {text}
      </Link>
    )
  }
  if (to || externalLink) {
    return (
      <Link
        notLink={notLink}
        to={to}
        href={externalLink}
        key={id}
        target={btnTheme.target}
      >
        <Button {...btnTheme} tracking={tracking}>
          {text}
        </Button>
      </Link>
    )
  }
  return (
    <Button {...btnTheme} key={id} tracking={tracking}>
      {text}
    </Button>
  )
}

const StyledCTA = styled.div`
  display: inline-grid;
  grid-template-columns: ${({ ctasLength }) => `repeat(${ctasLength}, auto)`};
  grid-gap: var(--cta-grid-gap);
  align-items: center;
`

const CtaGroup = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: var(--sp-24);

  @media ${device.laptop} {
    align-items: center;
    grid-template-columns: ${({ hasDetails }) =>
      hasDetails ? "auto auto" : "auto"};
  }
`

CTA.defaultProps = {
  ctas: [],
  theme: [],
  arrow: false,
}

CTA.propTypes = {
  ctas: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      to: PropTypes.string,
      url: PropTypes.string,
    })
  ),
  theme: PropTypes.array,
}

export default CTA
