import { css } from "styled-components"

const markdownStyles = css`
  ul {
    list-style-type: disc;
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--sp-24);

    p {
      /* font-weight: 600; */
    }

    ul {
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: var(--sp-8);
      margin-left: var(--sp-16);
      list-style-type: circle;

      p {
        font-size: 0.875rem;
        line-height: 22px;
        font-weight: 400;
      }
    }
  }
`
export default markdownStyles
