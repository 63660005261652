import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

// import { HomeBanner } from "src/sections/home"

import Footer from "./Footer"

import ErrorBoundary from "./ErrorBoundary"

import { useSetVh } from "utils"

const Layout = ({ children, hideFooter }) => {
  useSetVh()

  return (
    <Container>
      <ErrorBoundary>
        {/* <HomeBanner /> */}
        <main>{children}</main>
      </ErrorBoundary>
      {!hideFooter && <Footer />}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  flex: 1;

  /* position: relative; */

  main {
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  .grid-lines {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: grid;
    grid-template-columns: repeat(8, 1fr);
    grid-gap: var(--sp-16);
    z-index: 1;
    span {
      border-left: 1px solid #eee9e4;
    }
  }
`

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
