import { useEffect } from "react"

// Refused to display 'https://www.opero.com/' in a frame because it set 'X-Frame-Options' to 'deny'.

// So what will happen is that if you submit it, the iFrame will show up another webpage inside the existing webpage. It will look like there will be 2 company logo and 2 menus. It is possible that you have a script or a code that prevents that which is related to content origin or x-frame.

// I suggest you "escape" the iFrame after hitting the Submit button so that the user will be redirected to the actual page: https://www.opero.com/support/success/

// Instead of that page to appear inside the iFrame.

// You can use this custom script code to break out of iFrame when redirected to Thank You Page:

// <script type="text/javascript">
// if (top !=self) {
// top.location=self.location;
// }
// </script>

// Please put that code at the <head> section of the Thank You Page which is: https://www.opero.com/support/success/

// **** IMPORTANT ******
// gatsby-plugin-netlify adds "X-Frame-Options: DENY" > need to update config to:

// {
//     resolve: `gatsby-plugin-netlify`, // if using netlify
//     options: {
//       headers: {
//         "/support/success": ["X-Frame-Options: SAMEORIGIN"],
//         "/become-partner/success": ["X-Frame-Options: SAMEORIGIN"],
//       },
//     }

const useIframeBreak = () => {
  return useEffect(() => {
    if (typeof window !== "undefined") {
      const top = window.top
      const self = window.self
      if (top !== self) {
        top.location = self.location
      }
    }
  }, [])
}

export default useIframeBreak
