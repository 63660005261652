import React, { useEffect, useState } from "react"
import ReactPlayer from "react-player"
import styled from "styled-components"
import { useInView, device } from "utils"

const Video = ({
  desktop,
  mobile,
  muted,
  autoPlay,
  loop,
  youtube,
  ...other
}) => {
  const { ref, isVisible } = useInView()

  const [playing, setPlaying] = useState(false)

  useEffect(() => {
    if (isVisible) {
      setPlaying(true)
    } else {
      setPlaying(false)
    }
  }, [isVisible])

  if (youtube) {
    return (
      <VideoWrap className="videoWrap">
        <Youtube
          src={youtube.src}
          width="560"
          height="315"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          {...youtube}
          {...other}
        />
      </VideoWrap>
    )
  }

  return (
    <VideoWrap className="videoWrap" ref={ref}>
      <ReactPlayer
        className="video desktop"
        width="100%"
        height="100%"
        playing={playing}
        url={desktop}
        muted={muted}
        autoPlay={autoPlay}
        loop={loop}
      />
      <ReactPlayer
        className="video mobile"
        width="100%"
        height="100%"
        playing={playing}
        url={mobile}
        muted={muted}
        autoPlay={autoPlay}
        loop={loop}
      />
    </VideoWrap>
  )
}

const VideoWrap = styled.div`
  height: 100%;
  width: 100%;

  .desktop {
    display: none;
  }
  @media ${device.laptop} {
    .mobile {
      display: none;
    }
    .desktop {
      display: block;
    }
  }
`

const Youtube = styled.iframe`
  width: 100vw;

  @media ${device.laptop} {
    height: 450px;
    width: 800px;
  }

  @media ${device.desktopL} {
    width: 1080px;
    height: 600px;
  }
`

Video.defaultProps = {
  src: "",
  muted: true,
  autoPlay: false,
  loop: true,
}

export default Video
