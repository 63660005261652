import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import { BasicAccordion, Link } from "components/common"
import { appTextColors } from "utils"
import appNavLinks from "utils/global/appNavigation"

const AppsAccordionNav = () => {
  const renderNav = () =>
    map(appNavLinks, ({ text, to, id }) => (
      <li key={text}>
        <Link to={to}>
          <h5>
            {text}
            <strong className={id}>.</strong>
          </h5>
        </Link>
      </li>
    ))

  return (
    <StyledAppsAccordionNav>
      <BasicAccordion title="Apps" titleAs="h6">
        <ul>{renderNav()}</ul>
      </BasicAccordion>
    </StyledAppsAccordionNav>
  )
}

const StyledAppsAccordionNav = styled.div`
  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;

    li {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        right: 80px;
        left: 0;
        top: -1px;
        height: 2px;
        background: linear-gradient(
          90deg,
          #505156 84.86%,
          rgba(80, 81, 86, 0) 100%
        );
      }
      a {
        display: block;
        padding: var(--sp-section-lr);

        transition: opacity 0.2s ease-in-out;
        will-change: opacity;

        &:hover {
          opacity: 0.8;
        }

        h5 {
          strong {
            ${appTextColors};
          }
        }
      }
    }
  }

  .other {
    display: grid;
    grid-template-columns: 1fr;

    a {
      display: block;
      padding: var(--sp-section-lr);

      transition: opacity 0.2s ease-in-out;
      will-change: opacity;

      &:hover {
        opacity: 0.8;
      }
    }
  }
`

export default AppsAccordionNav
