import React from "react"
import styled from "styled-components"
import map from "lodash/map"
import { BasicAccordion, Link } from "components/common"

const NavAccordion = ({ title, links, close }) => {
  const renderNav = () =>
    map(links, ({ text, to, href, scroll }) => (
      <li key={text}>
        <Link to={to} href={href} scroll={scroll} onClick={close}>
          {text}
        </Link>
      </li>
    ))

  return (
    <StyledAccordionNav>
      <BasicAccordion title={title} titleAs="h6">
        <ul>{renderNav()}</ul>
      </BasicAccordion>
    </StyledAccordionNav>
  )
}

const StyledAccordionNav = styled.div`
  ul {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;

    li {
      position: relative;
      &:after {
        content: "";
        position: absolute;
        right: 80px;
        left: 0;
        top: 0;
        height: 1px;
        background: var(--nav-accordion-divider-color);
      }
      a {
        font-size: 0.875rem;
        line-height: 1;
        display: block;
        padding: var(--sp-section-lr);
        font-weight: 700;
        font-family: var(--body-font-family);
        transition: opacity 0.2s ease-in-out;
        will-change: opacity;

        &:hover {
          opacity: 0.8;
        }
      }
    }
  }
`

export default NavAccordion
