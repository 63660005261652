import { css } from "styled-components"

export default css`
  background: rgba(255, 255, 255, 0.64);
  border: 2px solid #ffffff;
  box-shadow: 0px 8px 32px rgba(233, 222, 216, 0.48),
    inset 0px 2px 8px rgba(249, 249, 249, 0.2);
  backdrop-filter: blur(16px);
  border-radius: 24px;

  @-moz-document url-prefix() {
    background: rgba(255, 255, 255, 0.9);
  }
`
