import React, { useCallback } from "react"
import styled from "styled-components"
import PropTypes from "prop-types"

import { useRichText } from "utils/hooks"

import { Media } from "components/common"

/**
 * @param {object} props
 * @param {(string | object)} props.hat
 * @param {string} props.hatAs
 * @param {(string | object)} props.title
 * @param {string} props.titleAs
 * @param {(string | object)} props.subtitle
 * @param {string} props.subtitleAs
 * @param {function} props.cta button or cta?
 */

const TextGroup = ({
  hatIcon,
  hat,
  title,
  subtitle,
  hatAs,
  titleAs,
  subtitleAs,
  cta,
  custom,
  ...other
}) => {
  const renderHat = useRichText({ text: hat, className: "hat", as: hatAs })

  const renderTitle = useRichText({
    text: title,
    className: "title",
    as: titleAs,
  })

  const renderSubtitle = useRichText({
    text: subtitle,
    className: "subtitle",
    as: subtitleAs,
  })

  const renderCta = useCallback(() => cta(), [cta])

  return (
    <StyledTextGroup {...other}>
      <div className="tgWrap">
        {hatIcon && (
          <div className="hatIconWrap">
            <Media
              className="hatIcon"
              media={hatIcon}
              alt={hatIcon.description}
            />
          </div>
        )}

        {renderHat}
        {renderTitle}
        {renderSubtitle}

        {cta && <Cta className="cta">{renderCta()}</Cta>}

        {custom && <div className="customWrap">{custom()}</div>}
      </div>
    </StyledTextGroup>
  )
}

const StyledTextGroup = styled.div`
  .tgWrap {
    text-align: var(--tg-text-align);
    max-width: var(--tg-max-width);
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--tg-grid-gap);

    .hatIconWrap {
      display: flex;
      justify-content: flex-start;
    }

    .hat {
      color: var(--tg-hat-color);
      text-transform: uppercase;
    }

    .title {
      color: var(--tg-title-color);
    }

    .subtitle {
      color: var(--tg-subtitle-color);
    }
  }
`
const Cta = styled.div`
  /* margin-top: var(--sp-32); */
  /* margin-top: var(--sp-8); */
`

TextGroup.defaultProps = {
  hat: "",
  hatAs: "h4",
  title: "",
  titleAs: "h2",
  subitle: "",
  subtitleAs: "p",
  cta: null,
}

TextGroup.propTypes = {
  hat: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  hatAs: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  titleAs: PropTypes.string,
  subtitle: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  subtitleAs: PropTypes.string,
  cta: PropTypes.func,

  textAlign: PropTypes.string,
}

export default TextGroup
