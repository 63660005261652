import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import { TextGroup, Cta, Link, Media } from "components/common"
import { device } from "src/utils"

// import { device } from "utils"

/**
 * @description Default ValueProp
 *
 * @typedef {{
 *  textAlign: "left" | "right" | "center"
 *  icon: {}
 *  hat: String
 *  hatAs: String
 *  title: String
 *  titleAs: String
 *  subtitle: String
 *  subtitleAs: String
 *  ctas: []
 *  ctasTheme: []
 *  href: String
 *  to: String
 *  className: String
 *  backgroundImage: {}
 * orientation: "vertical" | "horizontal"
 * }}
 *
 * Props
 * @type React.FunctionComponent<Props>
 */

const ValueProp = (props) => {
  const { to, href, disableLink } = props

  // want to remove the link that gets created with to/href
  if (disableLink) {
    return <Vp {...props} />
  }

  if (to || href) {
    return (
      <Link to={to} href={href}>
        <Vp {...props} />
      </Link>
    )
  }

  return <Vp {...props} />
}

const Vp = ({
  icon,
  hat,
  hatAs,
  title,
  titleAs,
  subtitle,
  subtitleAs,
  to,
  href,
  ctas,
  orientation,
  className,
  ctasTheme,
  as,
  inlineSvg,
  disableLink,
}) => {
  const notLink = disableLink !== "undefined" ? disableLink : !href || !to

  return (
    <StyledValueProp as={as} className={className} orientation={orientation}>
      {icon && (
        <StyledImage className="vp_mediaContainer">
          <Media
            media={{ desktop: icon }}
            alt={icon.description}
            inlineSvg={inlineSvg}
          />
        </StyledImage>
      )}
      <TextWrap className="vp_textContainer" orientation={orientation}>
        <TextGroup
          hat={hat}
          hatAs={hatAs}
          titleAs={titleAs}
          title={title}
          subtitle={subtitle}
          subtitleAs={subtitleAs}
        />
        {ctas && (
          <CTAWrap className="vp_ctaContainer">
            <Cta notLink={notLink} ctas={ctas} theme={ctasTheme} />
          </CTAWrap>
        )}
      </TextWrap>
    </StyledValueProp>
  )
}

const ORIENTATION_LIB = {
  horizontal: "var(--vp-col)",
  vertical: "1fr",
}

const StyledValueProp = styled.li`
  display: grid;
  align-items: var(--vp-mobile-align);
  grid-template-columns: 1fr;
  grid-gap: var(--vp-mobile-grid-gap);
  grid-template-rows: max-content;
  @media ${device.laptop} {
    align-items: var(--vp-align);
    grid-gap: var(--vp-grid-gap);
    grid-template-columns: ${({ orientation }) => ORIENTATION_LIB[orientation]};
  }
`

const TextWrap = styled.div`
  @media ${device.laptop} {
    grid-row-start: ${({ orientation }) =>
      orientation === "vertical" ? 2 : 1};
    grid-column-start: auto;
  }
`

const CTAWrap = styled.div`
  /* margin-top: var(--sp-24); */
  margin-top: var(--sp-16);
`

const StyledImage = styled.div`
  grid-row-start: 1;
  grid-column-start: auto;
  text-align: ${({ textAlign }) => textAlign};
  /* margin-bottom: var(--sp-16); */
`

ValueProp.defaultProps = {
  align: "left",
  orientation: "vertical",
  hat: "",
  hatAs: "h4",
  title: "",
  titleAs: "h3",
  subtitle: "",
  subtitleAs: "p",

  icon: null,

  cta: null,
  href: "",
  to: "",
  disableLink: false,
  inlineSvg: false,
}
ValueProp.propTypes = {
  align: PropTypes.oneOf(["left", "center", "right"]).isRequired,
  orientation: PropTypes.oneOf(["vertical", "horizontal"]).isRequired,
  title: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  subtitle: PropTypes.oneOfType([
    PropTypes.shape({
      raw: PropTypes.string.isRequired,
    }),
    PropTypes.string,
  ]),
  hasAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "pSmall"]),
  titleAs: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6", "p", "pSmall"]),
  subtitleAs: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "pSmall",
  ]),

  media: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
    fixed: PropTypes.shape({
      width: PropTypes.number,
      height: PropTypes.number,
    }),
    file: PropTypes.shape({
      url: PropTypes.string,
      fileName: PropTypes.string,
    }),
    fluid: PropTypes.shape({ sizes: PropTypes.string }),
  }),

  ctas: PropTypes.arrayOf(PropTypes.object),
  ctasTheme: PropTypes.arrayOf(PropTypes.object),
  href: PropTypes.string,
  to: PropTypes.string,
}

export default ValueProp
