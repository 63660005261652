import React from "react"
import styled from "styled-components"
import classNames from "classnames"
import { useRichText, device } from "utils"

const MultiColumnContent = ({ col1, col2, col3 }) => {
  const renderCol1 = useRichText({ text: col1 })
  const renderCol2 = useRichText({ text: col2 })
  const renderCol3 = useRichText({ text: col3 })

  return (
    <StyledMultiColumnContent className={classNames({ col3 })}>
      <li className="col1">{renderCol1}</li>
      <li className="col2">{renderCol2}</li>
      {renderCol3 && <li className="col3">{renderCol3}</li>}
    </StyledMultiColumnContent>
  )
}

const StyledMultiColumnContent = styled.ul`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: var(--sp-24);
  padding-left: 0 !important;

  @media ${device.laptop} {
    grid-gap: var(--sp-32);
    grid-template-columns: 1fr 1fr;

    &.col3 {
      grid-template-columns: 1fr 1fr 1fr;
    }
  }

  li {
    list-style-type: none;
  }
`

export default MultiColumnContent
