import { AnimatePresence, motion } from "framer-motion"
import { StaticImage } from "gatsby-plugin-image"
import React, { useRef, useState, useEffect } from "react"

import styled from "styled-components"

import Close from "src/images/common/close.inline.svg"
import { device } from "src/utils"

export default function StickyBanner() {
  const [show, setShow] = useState(true)
  const [forceHidden, setForceHidden] = useState(true)

  const lastScrollYRef = useRef(0)

  const hideBanner = () => {
    window.localStorage.setItem("forceHidden", "true")
    setForceHidden(true)
  }

  const controlNavbar = () => {
    if (window.scrollY > lastScrollYRef.current) {
      // if scroll down hide the navbar
      setShow(false)
    } else {
      // if scroll up show the navbar
      setShow(true)
    }

    // remember current page location to use in the next move
    lastScrollYRef.current = window.scrollY
  }

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar)

    // cleanup function
    return () => {
      window.removeEventListener("scroll", controlNavbar)
    }
  }, [])

  useEffect(() => {
    const hidden = window.localStorage.getItem("forceHidden")
    if (hidden === "true") {
      setForceHidden(true)
    } else {
      setForceHidden(false)
    }
  }, [setForceHidden])

  return (
    <>
      {!forceHidden && <Spacer />}

      <AnimatePresence>
        {show && !forceHidden && (
          <StyledStickyBanner
            transition="linear"
            initial="hidden"
            animate="show"
            exit="hidden"
            variants={{
              hidden: { opacity: 0 },
              show: { opacity: 1 },
            }}
          >
            <div className="spacer" />
            <div className="center">
              <StaticImage
                className="logo"
                src="../../../images/nav/concord/ConcordOperonologoLight.png"
                alt="Opero a Concord technologies company"
                width={180}
                loading="eager"
                placeholder="none"
              />
              <p>Opero is now a Concord Technologies company. </p>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href="https://concord.net/concord-technologies-acquires-opero"
              >
                LEARN MORE
              </a>
            </div>

            <CloseButton onClick={hideBanner}>
              <Close />
            </CloseButton>
          </StyledStickyBanner>
        )}
      </AnimatePresence>
    </>
  )
}

const Spacer = styled.div`
  height: 60px;
  width: 100%;
  display: none;

  @media ${device.laptop} {
    display: block;
  }
`

const StyledStickyBanner = styled(motion.section)`
  background: red;
  display: flex;
  align-items: center;
  justify-content: space-between;
  grid-gap: 16px;
  min-height: 60px;
  position: fixed;
  z-index: 21;
  left: 0;
  right: 0;
  background: linear-gradient(90deg, #23a7ad 0%, #61c57c 50%, #c85958 100%);
  padding: 0 12px;
  bottom: 0;

  @media ${device.laptop} {
    padding: 0 24px;
    top: 0;
    bottom: initial;
  }

  .spacer {
    /* display: none;
    @media ${device.laptop} {
      display: inline-block;
    } */
  }

  .center {
    display: flex;
    align-items: center;
    justify-content: center;

    grid-gap: 8px;

    @media ${device.laptop} {
      grid-gap: 24px;
    }
  }

  .logo {
    max-width: 100%;
    height: auto;
  }

  p {
    --font-body-color: white !important;
    color: white !important;
    text-align: center;
    display: none;
    @media ${device.laptop} {
      display: inline-block;
    }
  }

  a,
  p {
    font-size: 0.75rem;
    line-height: 1;
    color: white !important;

    @media ${device.laptop} {
      font-size: 1rem;
    }
  }

  a {
    display: inline-block;
    font-weight: normal;
    background: white;
    color: black !important;
    padding: 8px 16px;
    border-radius: 20px;
    font-size: 14px;
    text-align: center;
    transition: 0.2s opacity ease-in-out;
    min-width: 123px;

    &:hover {
      opacity: 0.8;
    }
  }
`

const CloseButton = styled.button`
  transition: 0.2s opacity ease-in-out;
  cursor: pointer;
  &:hover {
    opacity: 0.5;
  }

  svg {
    path {
      stroke: white;
    }
  }
`
